import React, {useState, useEffect, Fragment, useMemo} from "react";
import { Link, useHistory } from "react-router-dom";
import {showErrorMsg,showSuccessMsg} from '../helpers/message';
import {showLoading, showLoadingGif} from '../helpers/loading';
import isEmpty from 'validator/lib/isEmpty';
import isUrl from 'validator/lib/isURL';
import { getalllinkunique, getlinkuniqueanalytics } from "../api/auth";
import { isAuthenticated } from "../helpers/auth";
import Tooltip from './Tooltip';
import { Toast, Form } from 'react-bootstrap'
import { Chart } from "react-google-charts";
import './Dashboard.css';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import Pagination from './Pagination';
import ReactExport from "react-export-excel";


//redux
import {useSelector, useDispatch} from 'react-redux';
import {getLink,getLinks, getLinksbyDate} from '../redux/actions/linkActions';
import "../../node_modules/flag-icons/css/flag-icons.min.css";
import Pagination2 from "./Pagination2";
import ToastContainer from 'react-bootstrap/ToastContainer';
import PaginationChart from "./PaginationChart";
import ProgressBar from 'react-bootstrap/ProgressBar';

const queryString = require('query-string');



const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const UAnalytics = () => {

const dispatch = useDispatch();

let history = useHistory();



const [checkState,setCheckState] = useState(false);

const [currentPage, setCurrentPage] = useState(1);
const [currentPageBrowser, setCurrentPageBrowser] = useState(1);
const [currentPageOS, setCurrentPageOS] = useState(1);
const [currentPageDevice, setCurrentPageDevice] = useState(1);
const [currentPageReferrer, setCurrentPageReferrer] = useState(1);
const [currentPageCountry, setCurrentPageCountry] = useState(1);
const [currentPageCity, setCurrentPageCity] = useState(1);

const [recordsPerPage] = useState(5);

const [showToast, setToast] = useState(false)

const [qrUrl,setQrUrl] = useState('');
const [qrName,setQrName] = useState('');
const [deleteTitle,setDeleteTitle] = useState('');
const [deleteID,setDeleteID] = useState('');
const [stateDate, setStateDate] = useState(new Date().toISOString().split("T")[0]);

const [text, setText] = useState('');

const [showModal, setShowModal] = useState(false);
const [showDeleteModal, setShowDeleteModal] = useState(false);
const handleQRShow = () => setShowModal(true);
const handleQRClose = () => setShowModal(false);
const handleDeleteClose = () => setShowDeleteModal(false);
const handleDeleteShow = () => setShowDeleteModal(true);



 //COMPONENT STATE PROPERTIES
 const [formData, setFormData] = useState({
    linkId:'',
    shortUrl:'',
    longUrl:'',
    title:'',
    userId:isAuthenticated()._id,
    teamId: isAuthenticated().teamId,
    //date:new Date().toISOString().split("T")[0],
    date: moment().subtract(6, "days").format("YYYY-MM-DD"),
    date2: new Date().toISOString().split("T")[0],
    views:'',
    total_view:0,
    tekil:0,
    total_view_prev:0,
    tekil_prev:0,
    browser_data:'',
    os_data:'',
    click_date:'',
    device_data:'',
    country_data:'',
    city_data:'',
    referrer_data:'',
    tekil_link:'',
    click_date_unique:'',
    visitor_data:[],
    successMsg: false,
    errorMsg: false,
    loading: true  
});

const [linkFormData, setlinkFormData] = useState({
    userId2:isAuthenticated()._id,
    teamId2: isAuthenticated().teamId,
    date3: moment().subtract(6, "days").format("YYYY-MM-DD"),
    date4: new Date().toISOString().split("T")[0]
});


const {longUrl, shortUrl,title, userId, views, ios,date, date2, web, teamId, tekil, tekil_prev, total_view, total_view_prev, tekil_link, click_date_unique, browser_data, os_data, device_data,click_date, country_data, city_data, referrer_data,visitor_data,successMsg, errorMsg, loading } = formData;
const {userId2 ,date3, date4, teamId2 } = linkFormData;



useEffect(() => {
    dispatch(getLinks(userId));
}, [dispatch]);

// useEffect(() => {
//     dispatch(getLinksbyDate(linkFormData));
// }, [dispatch]); 

const {links} = useSelector(state=>state.links);





useEffect(() => {

    if(userId) {
            getlinkuniqueanalytics(formData)
                        .then(response => {
                                setFormData({
                                    ...formData,
                                    tekil: response.data.tekil,
                                    total_view: response.data.total_click,
                                    tekil_prev: response.data.tekil_prev,
                                    total_view_prev: response.data.total_click_prev,
                                    tekil_link: response.data.tekil_link,
                                    click_date_unique:response.data.click_date_unique,
                                    click_date:response.data.click_date,
                                    browser_data: response.data.browser,
                                    os_data: response.data.os,
                                    device_data: response.data.device,
                                    country_data: response.data.country,
                                    city_data: response.data.city,
                                    referrer_data: response.data.referrer,
                                    loading: false 
                                    // visitor_data: response.data.visitor
                                })  
                                                
                                                
                         })
                                      
                .catch(err => {
                    console.log('get link unique error:' , err);
                    
                }) 

    }
    
}, [userId])

useEffect(() => {

            getlinkuniqueanalytics(formData)
                        .then(response => {
                                setFormData({
                                    ...formData,
                                    tekil: response.data.tekil,
                                    total_view: response.data.total_click,
                                    tekil_prev: response.data.tekil_prev,
                                    total_view_prev: response.data.total_click_prev,
                                    tekil_link: response.data.tekil_link,
                                    click_date_unique:response.data.click_date_unique,
                                    click_date:response.data.click_date,
                                    browser_data: response.data.browser,
                                    os_data: response.data.os,
                                    device_data: response.data.device,
                                    country_data: response.data.country,
                                    city_data: response.data.city,
                                    referrer_data: response.data.referrer,
                                    loading: false 
                                    // visitor_data: response.data.visitor
                                })                  
                                                
                         })
                                      
                .catch(err => {
                    console.log('get link unique error:' , err);
                    
                }) 

    
    
}, [date,date2])

// useEffect(() => {


    
//     dispatch(getLinksbyDate(linkFormData));


// }, [date3,date4])






const ranges = {
    Today: [moment(), moment()],
    Yesterday: [
      moment().subtract(1, "days"),
      moment().subtract(1, "days")
    ],
    "Last 7 Days": [moment().subtract(6, "days"), moment()],
    "Last 30 Days": [moment().subtract(29, "days"), moment()],
    "This Month": [moment().startOf("month"), moment().endOf("month")],
    "Last Month": [
      moment()
        .subtract(1, "month")
        .startOf("month"),
      moment()
        .subtract(1, "month")
        .endOf("month")
    ]
  };

  const setDates = (e, { startDate, endDate }) => {
    setDatesState({
      startDate: startDate.format("YYYY-MM-DD"),
      endDate: endDate.format("YYYY-MM-DD")
    });
    setFormData({...formData, date: moment(startDate).format("YYYY-MM-DD"), date2: moment(endDate).format("YYYY-MM-DD"),loading:true});
    setlinkFormData({...linkFormData, date3: moment(startDate).format("YYYY-MM-DD"), date4: moment(endDate).format("YYYY-MM-DD")});
  };

  const handleViewCount = () => {
    setTimeout(() => {
     dispatch(getLinks(userId));
     if(userId) {
         getalllinkunique(formData)
                         .then(response => {
                                 setFormData({
                                     ...formData,
                                     tekil: response.data.tekil
                                 })  
                                                 
                                                 
                          })
                                       
                 .catch(err => {
                     console.log('get all link unique error:' , err);
                     
                 }) 
 
         }
 }, 5000);
     
 } ;


         // EVENT HANDLER
         const values2 = [["Task", "Hours per Day"]];

         const colors = ['#4c8df5', '#e46964', '#f6cf52', '#1da446', '#896cd7','#a58f4c'];

         const browser_data_2 = [];

         if(browser_data!='') {
             const obj_browser = Object.entries(browser_data && browser_data[0]);

             
             for (let i = 0; i < obj_browser.length; i++) {
                 browser_data_2.push({
                     name: obj_browser[i][0],
                     count: obj_browser[i][1]
                 });
             }

                 for (var i = 0; i < obj_browser.length; i++) {
                     values2.push(obj_browser[i]);
                 }

         }
         
         const os_data_3 = [];
         const os_data_2 = [["",  ""]];
         const os_data_4 = [["OS", "Clicks", {role: 'style'}]];
         if(os_data!='') {
             const obj_os = Object.entries(os_data && os_data[0]);
             for (let i = 0; i < obj_os.length; i++) {
                 os_data_3.push({
                     name: obj_os[i][0],
                     count: obj_os[i][1]
                 });
             }
             for (var i = 0; i < obj_os.length; i++) {
                 os_data_2.push(obj_os[i]);
                 os_data_4.push([obj_os[i][0],obj_os[i][1], colors[i]])
             }

         }


         const ulke_data = [["Country", "Popularity"]];
         const ulke_data_2 = [];
         if(country_data!='') {
             const obj_country = Object.entries(country_data && country_data[0]);
             for (var i = 0; i < obj_country.length; i++) {
                 ulke_data.push(obj_country[i]);
             }
             for (let i = 0; i < obj_country.length; i++) {
                ulke_data_2.push({
                    name: obj_country[i][0],
                    count: obj_country[i][1]
                });
            }

         }

         

          const device_data_3 = [];
          const device_data_4 = [["Device", "Clicks", {role: 'style'}]];
          const device_data_2 = [["",  ""]];
          //const device_data_2 = [["Device", "", {role: 'style'}]];
         if(device_data!='') {

             const obj_device = Object.entries(device_data && device_data[0]);

             for (let i = 0; i < obj_device.length; i++) {
                 device_data_3.push({
                     name: obj_device[i][0],
                     count: obj_device[i][1]
                 });
             }
             for (var i = 0; i < obj_device.length; i++) {
                 device_data_2.push(obj_device[i]);
                 device_data_4.push([obj_device[i][0],obj_device[i][1], colors[i]])
             }

             

         }


         const city_data_2 = [["City", "Popularity"]];
         const city_data_3 = [];
         if(city_data!=''){
             const obj_city = Object.entries(city_data && city_data[0]);
             for (var i = 0; i < obj_city.length; i++) {
                 if(obj_city[i][0]!=''){
                     city_data_2.push(obj_city[i]);
                 }
             }
             
             for (let i = 0; i < obj_city.length; i++) {
                 if(obj_city[i][0]!=''){
                 city_data_3.push({
                     name: obj_city[i][0],
                     count: obj_city[i][1]
                 });
             }
             }

         }


         const referrer_data_2 = [["Referrer", "Clicks"]];
         const referrer_data_3 = [];
         const referrer_data_4 = [["Referrer", "Clicks", {role: 'style'}]];
         if(referrer_data!=''){
             const obj_referrer = Object.entries(referrer_data && referrer_data[0]);

             for (var i = 0; i < obj_referrer.length; i++) {
                     referrer_data_2.push(obj_referrer[i]);
                     referrer_data_4.push([obj_referrer[i][0],obj_referrer[i][1], colors[i]])

             }

             for (let i = 0; i < obj_referrer.length; i++) {
                 referrer_data_3.push({
                     name: obj_referrer[i][0],
                     count: obj_referrer[i][1]
                 });
             }

         }
         
             

             const click_date_2 = [["Date", "Total Click", "Unique Click"]];
             const click_date_3 = [];
             for (let i = 0; i < click_date?.length; i++) {
                
                 click_date_2.push([click_date[i]['_id'],click_date[i]['count'], click_date_unique[i]['count']]);  
                 click_date_3.push({
                    _id: click_date[i]['_id'],
                    total: click_date[i]['count'],
                    unique:click_date_unique[i]['count']
                });
                
                
             }

             


             const tekil_links = [];
             for (let i = 0; i < tekil_link?.length; i++) {

                tekil_links.push([tekil_link[i]['_id'],tekil_link[i]['number']]);    
             }

         
             const click_data = [
                ["Date","Total Click", "Unique Click"],
                [new Date(2015, 0, 1), 1000, 400],
                [new Date(2015, 0, 2), 1170, 460],
                [new Date(2015, 0, 3), 660, 1120],
                [new Date(2015, 0, 4), 1030, 540],
              ];
         

         

     const chart_data = [
         ["Task", "Hours per Day"],
         ["Chrome", 200],
         ["Firefox", 100],
         ["Safari", 50],
         ];
       
         const options = {
         title: "",
         colors: ['#4c8df5', '#e46964', '#f6cf52', '#1da446', '#896cd7']
       };
     
  
       
       
        const line_options = {
         title: "Daily Click Performance",
         curveType: "function",
         legend: { position: "bottom" },
         hAxis: {
             title: 'Time of day',
             format: 'dd/MM'
         }
       };

       const line_ops = {
         title: '',
         height: 400,
         colors: ['#4c8df5', '#e46964', '#f6cf52', '#1da446', '#896cd7'],
         hAxis: {
             //format: 'M/d/yy',
           format: 'YY-M-dd',
           gridlines: {count: 15}
         },
         vAxis: {
           gridlines: {color: 'none'},
           minValue: 0
         }
       };

        const city_options = {
         title: "",
         chartArea: { width: "50%" },
         colors: ['#4c8df5', '#e46964', '#f6cf52', '#1da446', '#896cd7','#8a6bc6','#a58f4c'],
         hAxis: {
            minValue: 0,
          },
          hAxis: {
             gridlines: {
                 color: 'transparent'
             }
         },
          legend: { position: "none" },
       };

       const referrer_options = {
         chartArea: { width: "50%" },
         colors: ['#4c8df5', '#e46964', '#f6cf52', '#1da446', '#896cd7','#8a6bc6','#a58f4c'],
         hAxis: {
           minValue: 0,
         },
         hAxis: {
            gridlines: {
                color: 'transparent'
            }
        },
         legend: { position: "none" },
       };

        const bar_data = [
         ["",  ""],
         ["Mac",  500],
         ["iOS",  350],
         ["Android",  300],
         ["Windows",  250],
       ];
       
        const bar_options = {
         chart: {
           title: "Operating Systems (OS)",
           subtitle: "Clicks",
         },
         vAxis: {
            gridlines: {
                color: 'transparent'
            }
        },
         legend: { position: "none" },
         colors: ['#4c8df5', '#e46964', '#f6cf52', '#1da446', '#896cd7','#8a6bc6','#a58f4c']
       };

       const device_bar_options = {
        chart: {
          title: "Devices",
          subtitle: "Clicks",
        },
        colors: ['#4c8df5', '#e46964', '#f6cf52', '#1da446', '#896cd7','#8a6bc6']
      };

       const device_options = {
         chart: {
           title: "Devices",
           subtitle: "Clicks",
         },
         vAxis: {
            gridlines: {
                color: 'transparent'
            }
        },
         legend: { position: "none" },
         colors: ['#4c8df5', '#e46964', '#f6cf52', '#1da446', '#896cd7', '#8a6bc6','#a58f4c']
       };

       const geo_options = {
         chart: {
           title: "Click Geo",
           subtitle: "Sales, Expenses, and Profit: 2014-2017",
         },
         colors: ['#4c8df5'],
       };

        const geo_data = [
         ["Country", "Popularity"],
         ["Germany", 200],
         ["United States", 300],
         ["Brazil", 400],
         ["Canada", 500],
         ["France", 600],
         ["RU", 700],
       ];

       
      
        const click_options = {
        chart: {
          title: "Click Performance",
          subtitle: "Sales, Expenses, and Profit: 2014-2017",
        },
      };



 // COMPONENT GLOBAL STATE PROPERTIES 

 const [dates, setDatesState] = useState({
    startDate: moment().subtract(6, "days").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD")
  });

  //console.log(formData)
  //console.log(links);


        const protocol = window.location.protocol;
        const domain = window.location.hostname;
        const port = window.location.port;
        const baseurl = `${protocol}//${domain}${port? port : ""}`

        const myvisit = visitor_data.sort(function compare(a, b) {
            var dateA = new Date(a.time);
            var dateB = new Date(b.time);
            return dateB - dateA;
          });
          

        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

        const indexOfLastRecordBrowser = currentPageBrowser * recordsPerPage;
        const indexOfFirstRecordBrowser = indexOfLastRecordBrowser - recordsPerPage;

        const browser_5 = browser_data_2.slice(indexOfFirstRecordBrowser,indexOfLastRecordBrowser);

        const indexOfLastRecordOS = currentPageOS * recordsPerPage;
        const indexOfFirstRecordOS = indexOfLastRecordOS - recordsPerPage;

        const os_5 = os_data_3.slice(indexOfFirstRecordOS,indexOfLastRecordOS);

        const indexOfLastRecordDevice = currentPageDevice * recordsPerPage;
        const indexOfFirstRecordDevice = indexOfLastRecordDevice - recordsPerPage;
        
        const device_5 = device_data_3.slice(indexOfFirstRecordDevice,indexOfLastRecordDevice);

        const indexOfLastRecordReferrer = currentPageReferrer * recordsPerPage;
        const indexOfFirstRecordReferrer = indexOfLastRecordReferrer - recordsPerPage;
        
        const referrer_5 = referrer_data_3.slice(indexOfFirstRecordReferrer,indexOfLastRecordReferrer);

        const indexOfLastRecordCountry = currentPageCountry * recordsPerPage;
        const indexOfFirstRecordCountry = indexOfLastRecordCountry - recordsPerPage;
        
        const ulke_5 = ulke_data_2.slice(indexOfFirstRecordCountry,indexOfLastRecordCountry);

        const indexOfLastRecordCity= currentPageCity * recordsPerPage;
        const indexOfFirstRecordCity = indexOfLastRecordCity - recordsPerPage;

        const city_5 = city_data_3.slice(indexOfFirstRecordCity,indexOfLastRecordCity);

        

          
        const myvisit_2 = myvisit.slice(indexOfLastRecord, indexOfFirstRecord);
        

        //const nPages = Math.ceil(myvisit.length / recordsPerPage)
        const nPages = Math.ceil(links.length / recordsPerPage);

        const nPagesBrowser = Math.ceil(browser_data_2.length / recordsPerPage);

        const nPagesDevice = Math.ceil(device_data_3.length / recordsPerPage);

        const nPagesOS = Math.ceil(os_data_3.length / recordsPerPage);

        const nPagesReferrer = Math.ceil(referrer_data_3.length / recordsPerPage);

        const nPagesCountry = Math.ceil(ulke_data_2.length / recordsPerPage);

        const nPagesCity = Math.ceil(city_data_3.length / recordsPerPage);
    
        const linkRender = links ? links.filter(member => member.shortUrl) : [];
        
        
        const linkCount = linkRender.length;
        
        const totalViews = links ? links.map(points => points.views).reduce((acc, curr) => acc + parseInt(curr, 0), 0) : [];

        const a3 = Array.from(tekil_link).map(t1 => ({...t1, ...Array.from(links).find(t2 => t2._id === t1._id)}))
        
        const map = new Map();
        Array.from(a3).forEach(item => map.set(item._id, item));
        Array.from(links).forEach(item => map.set(item._id, {...map.get(item._id), ...item}));
        const mergedArr = Array.from(map.values());
                
        const sortedLinks = mergedArr.sort((a, b) => a.views < b.views ? 1 : -1)
        
        const currentRecords2 = links?.slice().reverse();
        const currentRecords = sortedLinks.slice(indexOfFirstRecord, indexOfLastRecord);

       

        const diffPercent = function(a, b) {
            if ((a != 0) && (b != 0)) {
                return  ( a<b ? "-" + ((b - a) * 100) / a : ((a - b) * 100) / b );
            } else {
                return null;
            }
            
           }

           const unique_ratio= diffPercent(tekil,tekil_prev);
           const total_ratio= diffPercent(total_view,total_view_prev);
        
        

        const linkParams = currentRecords && currentRecords.map(m => m.utmParamaters).filter(notUndefined => notUndefined !== undefined);;
        
        const result = linkParams.filter(function(item){
            return typeof item ==='array';  
        });


        const arrLength = (linkParams ?? []).length

        const params = []

        if(arrLength>1) {

            for(let p in linkParams){

                if(linkParams[p].length==0) {
                    params.push('')
                }
                linkParams[p].map(t=>{    
                    let urlObject = queryString.stringify({source: t.source, medium: t.medium, campaign: t.campaign, term: t.term, content: t.content}, { skipNulls: true, skipEmptyString: true });
                    params.push(urlObject)
                })
            }

        }
            
        
          
        


        return (
            <Fragment>

                {/* //HEADER  */}
            <header class="bg-surface-primary border-bottom pt-6">
            <div class="container-fluid">
                <div class="mb-npx">
                    <div class="row align-items-center">
                        <div class="col-sm-9 col-12 mb-4 mb-sm-0">
                            
                            <h1 class="h2 mb-0 ls-tight">Analytics</h1>
                        </div>
                        
                        <div class="col-sm-3 col-12 text-sm-end">
                            <div class="mx-n1">
                            <DateRangePicker
                                onApply={setDates}
                                initialSettings={{ startDate: moment().subtract(1, "week"), endDate: moment().add(1, "week"), ranges: ranges }}
                            >
                                <input
                                type="text"
                                value= {moment(dates.startDate).format('ll') + "-" + moment(dates.endDate).format('ll')}
                                className="form-control"
                                />
                            </DateRangePicker>
                            </div>
                        </div>
                    </div>
                    
                    <ul class="nav nav-tabs mt-4 overflow-x border-0">
                       
                    </ul>
                </div>
            </div>
            </header>
            <main class="py-6 bg-surface-secondary">

            {
                loading ? (<div className='text-center'>{showLoadingGif()}</div>) : (
                    <>
                    
                <div class="container-fluid">
               
               {/* //METRİCS  */}

                <div className='row pb-3'>
                        <div class="col-lg-4 col-md-4 col-sm-6 col-12 mb-2 mt-4">
                                <div class="card shadow border-0">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col">
                                                    <span class="h6 font-semibold text-muted text-sm d-block mb-2">All Links</span>
                                                    <span class="h3 font-bold mb-0">{linkCount}</span>
                                                    
                                                </div>
                                            </div>
                                            <div class="mt-2 mb-0 text-sm">
                                            <span class="badge badge-pill bg-soft-success text-success me-2">
                                                
                                            </span>
                                            <span class="text-nowrap text-xs text-muted"></span>
                                        </div>
                                        </div>
                                    </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-6 col-12 mb-2 mt-4">
                                <div class="card shadow border-0">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col">
                                                    <span class="h6 font-semibold text-muted text-sm d-block mb-2">Unique Clicks</span>
                                                    <span class="h3 font-bold mb-0">{tekil.toLocaleString('tr') && tekil.toLocaleString('tr')}</span>
                                                </div>
                                                <div class="col-auto">
                                                    <div class="icon icon-shape bg-tertiary text-white text-lg rounded-circle">
                                                        <i class="bi bi-credit-card"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mt-2 mb-0 text-sm">
                                            {unique_ratio<0 &&
                                                <>
                                                    <span class="badge badge-pill bg-soft-danger text-danger me-2">
                                                    <i class="bi bi-arrow-down me-1"></i>{ parseFloat(unique_ratio).toFixed(2)+"%"}
                                                </span>
                                                <span class="text-nowrap text-xs text-muted">Since previous period - {tekil_prev.toLocaleString('tr')}</span>
                                                </>
                                                }

                                                {unique_ratio>0 &&
                                                <>
                                                
                                                    <span class="badge badge-pill bg-soft-success text-success me-2">
                                                    <i class="bi bi-arrow-down me-1"></i>{ parseFloat(unique_ratio).toFixed(2)+"%"}
                                                </span>
                                                <span class="text-nowrap text-xs text-muted">Since previous period - {tekil_prev.toLocaleString('tr')}</span>
                                                </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-6 col-12 mb-2 mt-4">
                            <div class="card shadow border-0">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col">
                                                <span class="h6 font-semibold text-muted text-sm d-block mb-2">Total Clicks</span>
                                                <span class="h3 font-bold mb-0">{total_view.toLocaleString('tr') && total_view.toLocaleString('tr')}</span>
                                            </div>
                                            <div class="col-auto">
                                                <div class="icon icon-shape bg-tertiary text-white text-lg rounded-circle">
                                                    <i class="bi bi-credit-card"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-2 mb-0 text-sm">
                                        {total_ratio<0 &&
                                            <>
                                           
                                                    <span class="badge badge-pill bg-soft-danger text-danger me-2">
                                                    <i class="bi bi-arrow-down me-1"></i>{ parseFloat(total_ratio).toFixed(2)+"%"}
                                                </span>
                                                <span class="text-nowrap text-xs text-muted">Since previous period  - {total_view_prev.toLocaleString('tr')}</span>
                                                </>
                                                }

                                                {total_ratio>0 &&
                                                <>
                                                
                                                    <span class="badge badge-pill bg-soft-success text-success me-2">
                                                    <i class="bi bi-arrow-down me-1"></i>{ parseFloat(total_ratio).toFixed(2)+"%"}
                                                </span>
                                                <span class="text-nowrap text-xs text-muted">Since previous period  - {total_view_prev.toLocaleString('tr')}</span>
                                                </>
                                                }
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>

                {/* //LINE CLICKS  */}

                {click_date!='' &&
                    <div class="card shadow border-0 mt-2 mb-5">
                        <div class="row mt-3 mb-n2">
                            <div class="col-sm-6 ">
                            <div class="m-3 ms-5 mt-4" style={{display: "flex"}}>
                            <span class="h6 font-semibold text-muted text-sm">Clicks by Date</span> 
                            <Tooltip content="Total Clicks and Unique Clicks by Date" direction="right" delay="0">
                            <i className="far fa-question-circle ms-2 mx-auto"></i>
                            </Tooltip>
                            </div>
                            </div>
                            <div class="col-sm-6">
                            <div class="text-end m-3">
                                <ExcelFile element={<button class="btn btn-outline-secondary"><i class="fas fa-cloud-download-alt"></i> Export</button>}>
                                    <ExcelSheet data={click_date_3} name="Click Log">
                                        <ExcelColumn label="Date" value="_id"/>
                                        <ExcelColumn label="Total Clicks" value="total"/>
                                        <ExcelColumn label="Unique Clicks" value="unique"/>
                                    </ExcelSheet>
                                </ExcelFile>
                            </div>
                            </div>
                        </div>
                        <div class="card-body">
                    
                        
                        <div class="row pb-4">
                        <div class="col">
                        <Chart
                            chartType="LineChart"
                            width="100%"
                            height="400px"
                            data={click_date_2}
                            options={line_ops}
                            />
                        </div>
                        </div>
                        
                        
                    
                        </div>
                    </div>
                }

                {/* LINKS TABLE ANALYTICS  */}
                {currentRecords!='' &&
                    <div class="card shadow border-0 mb-7">
                        <div class="card-header">
                            <h5 class="mb-0">Links</h5>
                        </div>
                        <div class="table-responsive">
                            <table class="table table-hover table-nowrap">
                                <thead class="thead-light">
                                    <tr>
                                        <th scope="col">Links</th>
                                        <th scope="col"></th>
                                        <th scope="col">Total Clicks</th>
                                        <th scope="col">Date/Creator</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {currentRecords && currentRecords.map((link,index) => (
                                    <tr key={link._id}>
                                        <td>
                                        <div class="row mb-2 ms-1">
                                    <div class="ml-2">

                                    {isUrl(link.longUrl) && 
                                    <img src={`https://s2.googleusercontent.com/s2/favicons?domain=${link.longUrl}`}/>
                                    }
                                    {!isUrl(link.longUrl) && 
                                    <img src={``}/>
                                    }  
                                    
                                        <b> &nbsp; {link.title}</b>
                                    </div>
                                </div>
                                <div class="row ms-1">
                                    <div class="ml-2 mb-2">
                                   
                                    {params[index]
                                    ? <small>{link.longUrl}?{params[index]}</small>
                                    : <small>{link.longUrl}</small>
                                    }
                                                                         
                                    </div>
                                </div>
                                <div class="row ms-1">
                                <Link to={{pathname: `${baseurl}/${link.shortUrl}`}} target="_blank" onClick={handleViewCount}>{baseurl}/{link.shortUrl}</Link>
                                </div>
                                            
                                        </td>
                                        <td>
                                        <a href={`/edit/${link._id}`} class="btn d-inline-flex btn-sm btn-neutral border-base mx-1">
                                                        <span class=" pe-2">
                                                            <i class="fa-solid fa-pencil"></i>
                                                        </span>
                                                        <span>Edit</span>
                                                    </a>
                                                                                      
                                        </td>
                                        <td>
                                            <a href={`/insight/${link._id}`} class="btn btn-sm btn-neutral"> <i class="fa-solid fa-chart-simple"></i> {link.views.toLocaleString('tr')}</a>                                        
                                        </td>
                                        <td>
                                        <div class="row mb-2">
                                            <div class="ml-2 text-center">
                                                <Tooltip content={moment(link.createdAt).format('MMMM Do YYYY, h:mm:ss a')} direction="top" delay="0">
                                                {moment(link.createdAt).fromNow()}
                                                </Tooltip>
                                            </div>
                                        </div>

                                            <div class="row mb-2">
                                        <div class="ml-2 text-center">
                                        <span class="badge rounded-pill bg-dark">{link.userId.name}</span> 

                                            </div>
                                        </div>
                                        
                                        </td>
                                        
                                    </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div class="card-footer border-0 py-5">
                            <div class="row align-items-center">
                            <div class="col-sm-4">
                            <span class="text-muted text-sm-2">Showing {recordsPerPage} items out of {links.length} results found</span>
                            </div>
                            <div class="col-sm-8">
                                <PaginationChart
                                    nPages={nPages}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    itemCount= {links.length}
                                    recordsPerPage={recordsPerPage}
                                />
                            </div>
                            
                            </div>
                        </div>
                    </div>
                }

                {/*  BROWSER CHARTS  */}
                {browser_data!='' &&
                    <div class="card shadow border-0 mt-5 mb-5">
                        <div class="row mt-3 mb-n2">
                            <div class="col-sm-6 ">
                            <div class="m-3 ms-5 mt-4" style={{display: "flex"}}>
                            <span class="h6 font-semibold text-muted text-sm">Browser</span> 
                            <Tooltip content="Browser usage of users who click on the link" direction="right" delay="0">
                            <i className="far fa-question-circle ms-2 mx-auto"></i>
                            </Tooltip>
                            </div>
                            </div>
                            <div class="col-sm-6">
                            <div class="text-end m-3">
                                <ExcelFile element={<button class="btn btn-outline-secondary"><i class="fas fa-cloud-download-alt"></i> Export</button>}>
                                    <ExcelSheet data={browser_data_2} name="Click Log">
                                        <ExcelColumn label="Browser" value="name"/>
                                        <ExcelColumn label="Clicks" value="count"/>
                                    </ExcelSheet>
                                </ExcelFile>
                            </div>
                            </div>
                        </div>
                        <div class="card-body">
                        <div class="row pb-2">
                            <div class="col-xs-6 col-sm-6">
                                <Chart
                                chartType="PieChart"
                                data={values2}
                                options={options}
                                width={"100%"}
                                height={"400px"}
                                />
                            </div>
                            <div class="col-xs-6 col-sm-6">
                                    <table class="table table-hover">
                                    <thead class="table table-borderless">
                                        <tr class="table table-borderless">
                                        <th class="col-sm-1" ></th>
                                        <th class="col-sm-6" ></th>
                                        <th class="col-sm-3 text-center"></th>
                                        <th class="col-sm-2 text-center"></th>
                                        </tr>
                                    </thead>
                                    <tbody class="table align-middle table-hover">
                                    {browser_5?.map((item,index) => {
                                        
                                        return (
                                            <>
                                        <tr key={index}>
                                        <td>
                                            {currentPageBrowser===1 && 
                                            <>  {index+1}</>
                                            }

                                            {currentPageBrowser>1 && 
                                                <>  {((currentPageBrowser-1)*recordsPerPage)+(index+1)} </>
                                            }
                                            </td> 
                                        <td>
                                        {item.name}
                                        </td>                  
                                        <td> 
                                            <div>
                                            <ProgressBar now={parseFloat((item.count*100)/total_view).toFixed(2)} className="blue-progress-bar" />
                                            </div>
                                        </td>
                                        <td class="text-center">
                                        {item.count.toLocaleString('tr')}
                                        </td>   
                                        </tr>
                                            </>
                                        );
                                        
                                    })
                                    
                                    }

                                    </tbody>
                                    </table>
                                    {browser_data_2.length>recordsPerPage &&
                                    <PaginationChart
                                    nPages={nPagesBrowser}
                                    currentPage={currentPageBrowser}
                                    setCurrentPage={setCurrentPageBrowser}
                                    itemCount= {browser_data_2.length}
                                    recordsPerPage={recordsPerPage}
                                />
                                    
                                    }
                                    
                            </div>
                        </div>
                        
                        
                    
                        </div>
                    </div>
                }

                {/*  DEVICES CHARTS  */}
                {device_data!='' &&
                    <div class="card shadow border-0 mt-5 mb-5">
                        <div class="row mt-3 mb-n2">
                            <div class="col-sm-6 ">
                            <div class="m-3 ms-5 mt-4" style={{display: "flex"}}>
                            <span class="h6 font-semibold text-muted text-sm">Devices</span> 
                            <Tooltip content="Device usage of users who click on the link" direction="right" delay="0">
                            <i className="far fa-question-circle ms-2 mx-auto"></i>
                            </Tooltip>
                            </div>
                            </div>
                            <div class="col-sm-6">
                            <div class="text-end m-3">
                                <ExcelFile element={<button class="btn btn-outline-secondary"><i class="fas fa-cloud-download-alt"></i> Export</button>}>
                                    <ExcelSheet data={device_data_3} name="Click Log">
                                        <ExcelColumn label="Device" value="name"/>
                                        <ExcelColumn label="Clicks" value="count"/>
                                    </ExcelSheet>
                                </ExcelFile>
                            </div>
                            </div>
                        </div>
                        <div class="card-body">
                        <div class="row pb-2">
                            <div class="col-xs-6 col-sm-6">
                                <Chart
                                chartType="ColumnChart"
                                data={device_data_4}
                                options={device_options}
                                width={"100%"}
                                height={"400px"}
                                />
                            </div>
                            <div class="col-xs-6 col-sm-6">
                                    <table class="table table-hover">
                                    <thead class="table table-borderless">
                                        <tr class="table table-borderless">
                                        <th class="col-sm-1" ></th>
                                        <th class="col-sm-6" ></th>
                                        <th class="col-sm-3 text-center"></th>
                                        <th class="col-sm-2 text-center"></th>
                                        </tr>
                                    </thead>
                                    <tbody class="table align-middle table-hover">
                                    {device_5?.map((item,index) => {
                                        
                                        return (
                                            <>
                                        <tr key={index}>
                                        <td>
                                                                {currentPageDevice===1 && 
                                                                    <>  {index+1}</>
                                                                }

                                                                {currentPageDevice>1 && 
                                                                    <>  {((currentPageDevice-1)*recordsPerPage)+(index+1)} </>
                                                                }
                                                                </td> 
                                        <td>
                                        {item.name}
                                        </td>                  
                                        <td> 
                                            <div>
                                            <ProgressBar now={parseFloat((item.count*100)/total_view).toFixed(2)} className="blue-progress-bar" />
                                            
                                            </div>
                                        </td>
                                        <td class="text-center">
                                        {item.count.toLocaleString('tr')}
                                        </td>   
                                        </tr>
                                            </>
                                        );
                                        
                                    })
                                    
                                    }

                                    </tbody>
                                    </table>
                                    {device_data_3.length>recordsPerPage &&
                                    <PaginationChart
                                    nPages={nPagesDevice}
                                    currentPage={currentPageDevice}
                                    setCurrentPage={setCurrentPageDevice}
                                    itemCount= {device_data_3.length}
                                    recordsPerPage={recordsPerPage}
                                />
                                    
                                    }
                                    
                            </div>
                        </div>
                        
                        
                    
                        </div>
                    </div>
                }

                {/*  OS CHARTS  */}
                {os_data!='' &&
                    <div class="card shadow border-0 mt-5 mb-5">
                        <div class="row mt-3 mb-n2">
                            <div class="col-sm-6 ">
                            <div class="m-3 ms-5 mt-4" style={{display: "flex"}}>
                            <span class="h6 font-semibold text-muted text-sm">Operating System</span> 
                            <Tooltip content="Operating System usage of users who click on the link" direction="right" delay="0">
                            <i className="far fa-question-circle ms-2 mx-auto"></i>
                            </Tooltip>
                            </div>
                            </div>
                            <div class="col-sm-6">
                            <div class="text-end m-3">
                                <ExcelFile element={<button class="btn btn-outline-secondary"><i class="fas fa-cloud-download-alt"></i> Export</button>}>
                                    <ExcelSheet data={os_data_3} name="Click Log">
                                        <ExcelColumn label="Operating System" value="name"/>
                                        <ExcelColumn label="Clicks" value="count"/>
                                    </ExcelSheet>
                                </ExcelFile>
                            </div>
                            </div>
                        </div>
                        <div class="card-body">
                        <div class="row pb-2">
                            <div class="col-xs-6 col-sm-6">
                                <Chart
                                chartType="PieChart"
                                data={os_data_2}
                                options={options}
                                width={"100%"}
                                height={"400px"}
                                />
                            </div>
                            <div class="col-xs-6 col-sm-6">
                                    <table class="table table-hover">
                                    <thead class="table table-borderless">
                                        <tr class="table table-borderless">
                                        <th class="col-sm-1" ></th>
                                        <th class="col-sm-6" ></th>
                                        <th class="col-sm-3 text-center"></th>
                                        <th class="col-sm-2 text-center"></th>
                                        </tr>
                                    </thead>
                                    <tbody class="table align-middle table-hover">
                                    {os_5?.map((item,index) => {
                                        
                                        return (
                                            <>
                                        <tr key={index}>
                                        <td>
                                        {currentPageOS===1 && 
                                            <>  {index+1}</>
                                        }

                                        {currentPageOS>1 && 
                                            <>  {((currentPageOS-1)*recordsPerPage)+(index+1)} </>
                                        }
                                        </td> 
                                        <td>
                                        {item.name}
                                        </td>                  
                                        <td> 
                                            <div>
                                            <ProgressBar now={parseFloat((item.count*100)/total_view).toFixed(2)} className="blue-progress-bar" />
                                            </div>
                                        </td>
                                        <td class="text-center">
                                        {item.count.toLocaleString('tr')}
                                        </td>   
                                        </tr>
                                            </>
                                        );
                                        
                                    })
                                    
                                    }

                                    </tbody>
                                    </table>
                                    {os_data_3.length>recordsPerPage &&
                                    <PaginationChart
                                    nPages={nPagesOS}
                                    currentPage={currentPageOS}
                                    setCurrentPage={setCurrentPageOS}
                                    itemCount= {os_data_3.length}
                                    recordsPerPage={recordsPerPage}
                                />
                                    
                                    }
                                    
                            </div>
                        </div>
                        
                        
                    
                        </div>
                    </div>
                }

                {/*  REFERRER CHARTS  */}
                {referrer_data!='' &&
                    <div class="card shadow border-0 mt-5 mb-5">
                        <div class="row mt-3 mb-n2">
                            <div class="col-sm-6 ">
                            <div class="m-3 ms-5 mt-4" style={{display: "flex"}}>
                            <span class="h6 font-semibold text-muted text-sm">Referrer</span> 
                            <Tooltip content="Top referrers of users who click on the link" direction="right" delay="0">
                            <i className="far fa-question-circle ms-2 mx-auto"></i>
                            </Tooltip>
                            </div>
                            </div>
                            <div class="col-sm-6">
                            <div class="text-end m-3">
                                <ExcelFile element={<button class="btn btn-outline-secondary"><i class="fas fa-cloud-download-alt"></i> Export</button>}>
                                    <ExcelSheet data={referrer_data_3} name="Click Log">
                                        <ExcelColumn label="Referrer" value="name"/>
                                        <ExcelColumn label="Clicks" value="count"/>
                                    </ExcelSheet>
                                </ExcelFile>
                            </div>
                            </div>
                        </div>
                        <div class="card-body">
                        <div class="row pb-2">
                            <div class="col-xs-6 col-sm-6">
                                <Chart
                                    chartType="BarChart"
                                    width="100%"
                                    height="300px"
                                    data={referrer_data_4}
                                    options={referrer_options}
                                />
                            </div>
                            <div class="col-xs-6 col-sm-6">
                                    <table class="table table-hover">
                                    <thead class="table table-borderless">
                                        <tr class="table table-borderless">
                                        <th class="col-sm-1" ></th>
                                        <th class="col-sm-6" ></th>
                                        <th class="col-sm-3 text-center"></th>
                                        <th class="col-sm-2 text-center"></th>
                                        </tr>
                                    </thead>
                                    <tbody class="table align-middle table-hover">
                                    {referrer_5?.map((item,index) => {
                                        
                                        return (
                                            <>
                                        <tr key={index}>
                                        <td>
                                        {currentPageReferrer===1 && 
                                            <>  {index+1}</>
                                        }

                                        {currentPageReferrer>1 && 
                                            <>  {((currentPageReferrer-1)*recordsPerPage)+(index+1)} </>
                                        }
                                        </td> 
                                        <td>
                                        {item.name}
                                        </td>                  
                                        <td> 
                                            <div>
                                            <ProgressBar now={parseFloat((item.count*100)/total_view).toFixed(2)} className="blue-progress-bar" />
                                            
                                            </div>
                                        </td>
                                        <td class="text-center">
                                        {item.count.toLocaleString('tr')}
                                        </td>   
                                        </tr>
                                            </>
                                        );
                                        
                                    })
                                    
                                    }

                                    </tbody>
                                    </table>
                                    {referrer_data_3.length>recordsPerPage &&
                                    <PaginationChart
                                    nPages={nPagesReferrer}
                                    currentPage={currentPageReferrer}
                                    setCurrentPage={setCurrentPageReferrer}
                                    itemCount= {referrer_data_3.length}
                                    recordsPerPage={recordsPerPage}
                                />
                                    
                                    }
                                    
                            </div>
                        </div>
                        
                        
                    
                        </div>
                    </div>
                }

                {/*  COUNTRY CHARTS  */}
                {country_data!='' &&
                    <div class="card shadow border-0 mt-5 mb-5">
                        <div class="row mt-3 mb-n2">
                            <div class="col-sm-6 ">
                            <div class="m-3 ms-5 mt-4" style={{display: "flex"}}>
                            <span class="h6 font-semibold text-muted text-sm">Country</span> 
                            <Tooltip content="Top countries of users who click on the link" direction="right" delay="0">
                            <i className="far fa-question-circle ms-2 mx-auto"></i>
                            </Tooltip>
                            </div>
                            </div>
                            <div class="col-sm-6">
                            <div class="text-end m-3">
                                <ExcelFile element={<button class="btn btn-outline-secondary"><i class="fas fa-cloud-download-alt"></i> Export</button>}>
                                    <ExcelSheet data={ulke_data_2} name="Click Log">
                                        <ExcelColumn label="Country" value="name"/>
                                        <ExcelColumn label="Clicks" value="count"/>
                                    </ExcelSheet>
                                </ExcelFile>
                            </div>
                            </div>
                        </div>
                        <div class="card-body">
                        <div class="row pb-2">
                            <div class="col-xs-6 col-sm-6">
                                <Chart
                                    chartEvents={[
                                        {
                                        eventName: "select",
                                        callback: ({ chartWrapper }) => {
                                            const chart = chartWrapper.getChart();
                                            const selection = chart.getSelection();
                                            if (selection.length === 0) return;
                                            const region = geo_data[selection[0].row + 1];
                                            console.log("Selected : " + region);
                                        },
                                        },
                                    ]}
                                    chartType="GeoChart"
                                    width="100%"
                                    height="400px"
                                    data={ulke_data}
                                    options={geo_options}
                                />
                            </div>
                            <div class="col-xs-6 col-sm-6">
                                    <table class="table table-hover">
                                    <thead class="table table-borderless">
                                        <tr class="table table-borderless">
                                        <th class="col-sm-1" ></th>
                                        <th class="col-sm-6" ></th>
                                        <th class="col-sm-3 text-center"></th>
                                        <th class="col-sm-2 text-center"></th>
                                        </tr>
                                    </thead>
                                    <tbody class="table align-middle table-hover">
                                    {ulke_5?.map((item,index) => {
                                        
                                        return (
                                            <>
                                        <tr key={index}>
                                        <td>
                                        {currentPageCountry===1 && 
                                            <>  {index+1}</>
                                        }

                                        {currentPageCountry>1 && 
                                            <>  {((currentPageCountry-1)*recordsPerPage)+(index+1)} </>
                                        }
                                        </td> 
                                        <td>
                                        {item.name}
                                        </td>                  
                                        <td> 
                                            <div>
                                            <ProgressBar now={parseFloat((item.count*100)/total_view).toFixed(2)} className="blue-progress-bar" />
                                            
                                            </div>
                                        </td>
                                        <td class="text-center">
                                        {item.count.toLocaleString('tr')}
                                        </td>   
                                        </tr>
                                            </>
                                        );
                                        
                                    })
                                    
                                    }

                                    </tbody>
                                    </table>
                                    {ulke_data_2.length>recordsPerPage &&
                                    <PaginationChart
                                    nPages={nPagesCountry}
                                    currentPage={currentPageCountry}
                                    setCurrentPage={setCurrentPageCountry}
                                    itemCount= {ulke_data_2.length}
                                    recordsPerPage={recordsPerPage}
                                />
                                    
                                    }
                                    
                            </div>
                        </div>
                        
                        
                    
                        </div>
                    </div>
                }

                {/*  CITY CHARTS  */}
                {city_data!='' &&
                    <div class="card shadow border-0 mt-5 mb-5">
                        <div class="row mt-3 mb-n2">
                            <div class="col-sm-6 ">
                            <div class="m-3 ms-5 mt-4" style={{display: "flex"}}>
                            <span class="h6 font-semibold text-muted text-sm">City</span> 
                            <Tooltip content="Top cities of users who click on the link" direction="right" delay="0">
                            <i className="far fa-question-circle ms-2 mx-auto"></i>
                            </Tooltip>
                            </div>
                            </div>
                            <div class="col-sm-6">
                            <div class="text-end m-3">
                                <ExcelFile element={<button class="btn btn-outline-secondary"><i class="fas fa-cloud-download-alt"></i> Export</button>}>
                                    <ExcelSheet data={city_data_3} name="Click Log">
                                        <ExcelColumn label="City" value="name"/>
                                        <ExcelColumn label="Clicks" value="count"/>
                                    </ExcelSheet>
                                </ExcelFile>
                            </div>
                            </div>
                        </div>
                        <div class="card-body">
                        <div class="row pb-2">
                            <div class="col-xs-6 col-sm-6">
                                <Chart
                                    chartType="BarChart"
                                    width="100%"
                                    height="300px"
                                    data={city_data_2}
                                    options={city_options}
                                />
                            </div>
                            <div class="col-xs-6 col-sm-6">
                                    <table class="table table-hover">
                                    <thead class="table table-borderless">
                                        <tr class="table table-borderless">
                                        <th class="col-sm-1" ></th>
                                        <th class="col-sm-6" ></th>
                                        <th class="col-sm-3 text-center"></th>
                                        <th class="col-sm-2 text-center"></th>
                                        </tr>
                                    </thead>
                                    <tbody class="table align-middle table-hover">
                                    {city_5?.map((item,index) => {
                                        
                                        return (
                                            <>
                                        <tr key={index}>
                                        <td>
                                        {currentPageCity===1 && 
                                            <>  {index+1}</>
                                        }

                                        {currentPageCity>1 && 
                                            <>  {((currentPageCity-1)*recordsPerPage)+(index+1)} </>
                                        }
                                        </td> 
                                        <td>
                                        {item.name}
                                        </td>                  
                                        <td> 
                                            <div>
                                            <ProgressBar now={parseFloat((item.count*100)/total_view).toFixed(2)} className="blue-progress-bar" />
                                            </div>
                                        </td>
                                        <td class="text-center">
                                        {item.count.toLocaleString('tr')}
                                        </td>   
                                        </tr>
                                            </>
                                        );
                                        
                                    })
                                    
                                    }

                                    </tbody>
                                    </table>
                                    {city_data_3.length>recordsPerPage &&
                                    <PaginationChart
                                    nPages={nPagesCity}
                                    currentPage={currentPageCity}
                                    setCurrentPage={setCurrentPageCity}
                                    itemCount= {city_data_3.length}
                                    recordsPerPage={recordsPerPage}
                                />
                                    
                                    }
                                    
                            </div>
                        </div>
                        
                        
                    
                        </div>
                    </div>
                }


                    {/* // Toast Container */}
                    <div>
                        <ToastContainer className="p-3" position='bottom-end'>
                        <Toast
                            onClose={() => setToast(false)}
                            autohide
                            show={showToast}
                            delay={3000}
                        >
                            <Toast.Header>
                            <strong className="mr-auto">muud.ly</strong>
                            
                            </Toast.Header>
                            <Toast.Body>URL has been copied.</Toast.Body>
                        </Toast>
                        </ToastContainer>
                    </div>
                

                {/* //LINE CLICKS  */}
                

                {visitor_data!='' &&
                    <div class="text-end">
                    <ExcelFile element={<button class="btn btn-primary">Export Excel</button>}>
                        <ExcelSheet data={myvisit} name="Click Log">
                            <ExcelColumn label="Visitor Type" value="visit_type"/>
                            <ExcelColumn label="Country" value="country_name"/>
                            <ExcelColumn label="City" value="city"/>
                            <ExcelColumn label="Visitor IP" value="ip"/>
                            <ExcelColumn label="Device" value="platform"/>
                            <ExcelColumn label="OS" value="os"/>
                            <ExcelColumn label="Browser" value="browser"/>
                            <ExcelColumn label="Time" value="time"/>
                        </ExcelSheet>

                    </ExcelFile>
                    </div>
                }

                {visitor_data!='' &&

                <div class="table-responsive ">
                <table class="table table-hover table-borderless">
                        <thead>
                            <tr class="table-light">
                            <th class="col-sm-2" >#</th>
                            <th class="col-sm-2" >Country</th>
                            <th class="col-sm-1" >City</th>
                            <th class="col-sm-1" >Visitor IP</th>
                            <th class="col-sm-1 text-center">Device</th>
                            <th class="col-sm-1 text-center">OS</th>
                            <th class="col-sm-1 text-center">Browser</th>
                            <th class="col-sm-2 text-center">Time</th>
                            
                            </tr>
                        </thead>
                        <tbody class="table-light align-middle table-bordered">
                        {myvisit_2?.map((item,index) => {
                            
                            return (
                        
                                <>
                            <tr key={index}>
                            <td>
                            {item.visit_type=== 'New Visitor' && 
                                    <i class="fa-solid fa-circle" style={{color: '#54de6b'}}></i> 
                            }
                            {item.visit_type=== 'Returning Visitor' && 
                                    <i class="fa-solid fa-circle" style={{color: '#ffd53d'}}></i> 
                            }
                             &nbsp;
                            {item.visit_type}
                            </td> 
                            <td>
                            <span class={`fi fi-${item.country_code.toLowerCase()}`}></span> &nbsp; {item.country_name}
                            </td> 
                            <td>
                            {item.city}
                            </td> 
                            <td>
                            {item.ip}
                            </td>  
                            <td class="text-center">
                            {item.platform=== 'Apple Mac' && 
                                    <i class="fa-sharp fa-solid fa-desktop"></i>  
                            }
                            {item.platform=== 'Linux' && 
                                    <i class="fa-sharp fa-solid fa-desktop"></i>  
                            }
                            {item.platform=== 'Windows' && 
                                    <i class="fa-sharp fa-solid fa-desktop"></i>  
                            }
                            {item.platform=== 'Android' && 
                                    <i class="fa-solid fa-mobile-screen-button"></i>  
                            }
                            {item.platform=== 'iPhone' && 
                                    <i class="fa-solid fa-mobile-screen-button"></i>  
                            } 
                            {item.platform=== 'iPad' && 
                                    <i class="fa-solid fa-tablet-screen-button"></i>  
                            } 
                            
                            &nbsp;
                            {item.platform}
                        
                            </td>
                            <td class="text-center">
                            {item.os=== 'Linux' && 
                                    <i class="fa-brands fa-linux"></i>  
                            }
                            {item.os=== 'OS X' && 
                                    <i class="fa-brands fa-apple"></i>  
                            }
                            &nbsp;
                            {item.os}
                            </td>  
                            <td class="text-center">
                                {item.browser==='Safari' && 
                                    <i class="fa-brands fa-safari"></i>  
                                }
                                {item.browser==='Chrome' && 
                                    <i class="fa-brands fa-chrome"></i>  
                                }
                                {item.browser==='Firefox' && 
                                    <i class="fa-brands fa-firefox"></i>  
                                }
                            &nbsp;
                            {item.browser}
                            </td>                     
                            <td class="text-center">                       
                            
                            {/* {moment(item.time).format('MMMM Do YYYY, h:mm:ss a')} */}
                            <Tooltip content={moment(item.time).format('MMMM Do YYYY, h:mm:ss a')} direction="top" delay="0">
                            {moment(item.time).fromNow()}
                            </Tooltip>
                            
                            </td>
                            </tr>
                            

                                </>
                          
                          );
                            
                        })
                        
                        }
                        
                        
                        </tbody>
                        
                </table>
                {/* <PaginationChart
                            nPages={nPages}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            itemCount= {myvisit.length}
                        /> */}

<Pagination2
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={myvisit.length}
        pageSize={recordsPerPage}
        onPageChange={page => setCurrentPage(page)}
      />
                </div>

                    }
                


                
                  </div>
                  </>
                  )}
                    </main>
                
            </Fragment>
        );

        
    };

export default UAnalytics;